import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import MapGoogle from "../components/MapGoogle";

const Contact = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-md-11">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9 heading-color">Contact us</h2>
                  <p className="gr-text-8 mb-13 text-color-opacity">
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className=" pb-5 pb-md-0 ">
          <Container>
            <Row className="justify-content-center">
              <Col md="6" lg="5" xl="6" className="order-md-2 mt-lg-11">
                <Row className="justify-content-center">
                  <Col xs="6" md="12" lg="10" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon gr-text-6 text-primary me-7 mt-1">
                        <i className="icon icon-phone-2"></i>
                      </div>
                      <div className="widget-text">
                        <p className="gr-text-7 mb-0 text-color-opacity">
                          +420 732 373 374 
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" md="12" lg="10" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon gr-text-6 text-primary me-7 mt-1">
                        <i className="icon icon-email-83"></i>
                      </div>
                      <div className="widget-text">
                        <p className="gr-text-7 mb-0 text-color-opacity">
                          tomas.karnis@kardef-group.com
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" md="12" lg="10" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon  gr-text-6 text-primary me-7 mt-1">
                        <i className="icon icon-pin-3-2"></i>
                      </div>
                      <div className="widget-text">
                        <p className="gr-text-7 mb-0 text-color-opacity">
                          Prague, Czech Republic
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="google-map fluid-map-height position-relative overflow-hidden"
          id="googleMap"
          css={`
            position: relative;
            overflow: hidden;
          `}
        >
          <div
            css={`
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              background-color: rgb(229, 227, 223);
            `}
          >
            <MapGoogle />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
